@font-face {
  font-family: "Roboto";
  /* src: url("/src/fonts/Solitreo-Regular.ttf") format("woff"), url("/src/fonts/VarelaRound-Regular.ttf") format("woff2"); */
  src: url("/src/fonts/Gveret\ Levin\ Alef\ Alef\ Alef.ttf") format("woff"),
    url("/src/fonts/Gveret\ Levin\ Alef\ Alef\ Alef.ttf") format("woff2");
  /* Add other font properties here, like font-weight and font-style if needed */
}
body {
  background-color: #0a0a0a !important;
  margin: 0;
  font-family: "Gveret Levin AlefAlefAlef";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* font-family: "Varela Round", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
