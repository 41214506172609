/* * {
  border: solid black 1px;
} */

.myBox {
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.master-wrap {
  margin-bottom: 350px !important;
}

/* ====================================== HomePage css ================================= */

.card-hover {
  filter: brightness(0.9);
}
.card-hover:hover {
  transition: all 0.5s;
  filter: contrast(1.2);
  filter: brightness(1.1);
}
.card-hover .MuiCardMedia-img:hover {
  transition: all 0.5s;
  filter: contrast(1.2);
  filter: brightness(1.1);
  /* transform: scale(1.2) !important; */
}

/* =================================  ViewCard Page  ==================================================== */
/* * {
  border: solid black 1px;
} */

.add-button {
  display: inline-block;
  transform: translateY(50vh) !important;
  transform: translateX(70vw) !important;
  position: fixed !important;
}

@keyframes anim-glow {
  0% {
    box-shadow: 0px 10px 17px 8px #e8e8e8;

    /* box-shadow: 0 0 rgba(#61ef61, 1); */
  }
  100% {
    box-shadow: 0 0 7px 8px transparent;
    border-width: 2px;
  }
}

.spots {
  background-color: #e8e8e8;
  border-radius: 30% 70% 34% 73% / 56% 55% 40% 30% !important;
  box-shadow: 0px 0px 11px 8px #e8e8e8;
  position: absolute;
  filter: blur(2px);

  animation: anim-glow 3s ease infinite;
}

.design-box {
  background-color: rgba(145, 145, 145, 0.324) !important;
  border-radius: 10px !important;
}

/* ====================================  infoPage  ================================== */

.user-info-wrap {
  margin-left: 7%;
}
/* =================================== sign Page ====================================*/
.sign-field {
  margin: 2px !important;
  width: 100%;
}

/* ===================== Icons ==================== */

.user-icon {
  width: 35px !important;
  height: 35px !important;
}

.logo {
  filter: brightness(1);
}
.logo:hover {
  transition: all 1s;
  filter: brightness(1.3);
  transform: translateX(-2px);
}

/* Styles for screens up to 768px wide */

@media (max-width: 600px) {
  .myBox {
    width: 90vw;
    margin-bottom: 5px;
    margin-top: 5px;
    flex-direction: column;
  }

  .master-wrap {
    margin-left: 24px;
    margin-bottom: 350px !important;
  }
  .view-main-wrap {
    flex-direction: column !important;
  }
  .user-info-wrap {
    margin-left: -4%;
  }
}

/* Styles for screens between 768px and 992px wide */
@media screen and (min-width: 768px) and (max-width: 992px) {
}

/* Styles for screens wider than 992px */
@media screen and (min-width: 992px) {
  .mainPageWrap {
    margin-left: 8vw !important;
  }

  .mainPageStyle {
    margin-left: 10% !important;
  }
}
